import axios, {AxiosError} from "axios";
import {Address} from "../models/Address";
import {Company} from "../models/Company";

const baseUrl = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL
const basePattern: string = "company";

export const findCompanyByCompanyId = async (accessToken: string, refreshToken: string, companyId: string) => {
    const url = `${baseUrl}/${basePattern}/${companyId}`;
    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}

export const findAllCompanies = async (accessToken: string, refreshToken: string) => {
    const url = `${baseUrl}/${basePattern}`;
    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });
        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}


/*export const findByIdWithAddress = async (accessToken: string, refreshToken: string, companyId: string) => {
    const url = `${baseUrl}/${basePattern}/findByIdWithAddress?id=${companyId}`;
    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data.data[0]
    } catch (err) {
        throw err as AxiosError;
    }
}*/

export const createCompany = async (accessToken: string, refreshToken: string, company: Partial<Company>) => {
    const url = `${baseUrl}/${basePattern}`;
    try {
        const response = await axios.post(
            url,
            company,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });
        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}

export const updateCompany = async (accessToken: string, refreshToken: string, company: Partial<Company>) => {
    const url = `${baseUrl}/${basePattern}`;
    try {
        const response = await axios.put(
            url,
            company,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });
        return response.data
    } catch (err) {
        console.log(err);
        throw err as AxiosError;
    }
}
