import React from 'react';
import "../App.css"
import {useTranslation} from "react-i18next";
import Header from "../components/Header/Header";
import Lottie from 'react-lottie-player'
import lottieJson from '../assets/animations/loadingLogo.json';

function NotFound() {
    const { t } = useTranslation();

    return (
        <div className="justify-center items-center mx-auto gradient_background">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Lottie
                    loop
                    speed={2.5}
                    animationData={lottieJson}
                    play
                    style={{ width: '50%', height: '50%' }}
                />
            </div>
        </div>
    );
}

export default NotFound;
