import axios, {AxiosError} from "axios";

const baseUrl = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL
const basePattern: string = "authentication";
export const login = async (_email: string, _password: string) => {
    const url = `${baseUrl}/${basePattern}/login`;
    try {
        const response = await axios.post(
            url,
            {
                email: _email,
                password: _password
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
        return response.data
    } catch (err) {
        console.log(err)
        throw err as AxiosError;
    }
}

export const register = async (email: string, password: string, companies: string[], firstName: string, lastName: string, active: boolean, features: string[], categories: string[]) => {
    const url = `${baseUrl}/${basePattern}/register`;
    try {
        const response = await axios.post(
            url,
            {
                email: email,
                active: active,
                password: password,
                companies: companies,
                features: features,
                categories: categories,
                firstName: firstName,
                lastName: lastName,
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

        return response.data
    } catch (err: AxiosError | any) {
        throw err as AxiosError;

    }
}

export const sendPasswordSetupEmail = async (accessToken: string, refreshToken: string, email: string) => {
    const url = `${baseUrl}/${basePattern}/sendPasswordSetupEmail`;
    try {
        const response = await axios.post(
            url,
            {
                email: email,
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data
    } catch (err: AxiosError | any) {
        throw err as AxiosError;

    }
}

export const getInitData = async (accessToken: string, refreshToken: string) => {
    const url = `${baseUrl}/${basePattern}/getInitData`;
    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });
        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}

export const setPasswordByLink = async (password: string, token: string) => {
    const url = `${baseUrl}/${basePattern}/setPasswordByLink`;
    try {
        const response = await axios.post(
            url,
            {
                password: password,
                token: token
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

        return response.data
    } catch (err: AxiosError | any) {
        throw err as AxiosError;

    }
}
