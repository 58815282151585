import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import {capitalizeFirstLetters} from "../../utils/StringUtils";
import {Company} from "../../models/Company";
import Lottie from "react-lottie-player";
import lottieJson from "../../assets/animations/loadingLogo.json";
import {findCompanyByCompanyId} from "../../interactors/CompanyInteractor";
import {useAuth} from "../../contexts/useAuth";
import {Address} from "../../models/Address";
import {deleteFile, getPreSignedUrl, uploadFileToS3} from "../../interactors/S3Interactor";

type HandleCompanyModalProps = {
    handleSubmitCb: (company: Partial<Company>, address: Partial<Address>) => void;
    company: Partial<Company>;
    type: "edit" | "create"

};

type FormData = {
    name: string;
    street: string;
    zipcode: string;
    floor?: string;
    city: string;
    country: string;
    houseNumber: string;
    email: string;
    phone: string;
    billingEmail: string;
    cvr: string;
};

export interface PreSignedPostData {
    url: string;
    fields: {
        [key: string]: string;
    };
}

export interface BackendResponse {
    data: PreSignedPostData;
}

const HandleCompanyModal: React.FC<HandleCompanyModalProps> = ({
                                                                   type,
                                                                   handleSubmitCb,
                                                                   company
                                                               }) => {
    const { t } = useTranslation();

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm<FormData>({});
    const {accessToken, refreshToken} = useAuth();
    const [logo, setLogo] = useState<string | ArrayBuffer | null | any>(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [uploadError, setUploadError] = useState('');

    const handleSetValues = (name: any, value: string) => {
        setValue(name, capitalizeFirstLetters(value ? value : ""));
    }

    const fetchCompany = async () => {
        const response: Company = await findCompanyByCompanyId(accessToken, refreshToken, company.id);
        const {zipcode, city, country, houseNumber, street, floor}: Address = response.address as any;
        const {name, phone, cvr, billingEmail, email}: Company = response;
        handleSetValues("name", name);
        handleSetValues("email", email);
        handleSetValues("billingEmail", billingEmail);
        handleSetValues("cvr", cvr);
        handleSetValues("phone", phone);
        handleSetValues("street", street);
        handleSetValues("zipcode", zipcode);
        handleSetValues("city", city);
        handleSetValues("country", country);
        handleSetValues("houseNumber", houseNumber);
        handleSetValues("floor", floor);
        await generateSignedUrl();
        setIsLoading(false);
    }

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (type === "edit") {
            fetchCompany();
        } else {
            setIsLoading(false);
        }
    }, [])

    const onSubmit: SubmitHandler<FormData> = async (data) => {

        const _company: Partial<Company> = {
            name: data.name,
            phone: data.phone,
            cvr: data.cvr,
            email: data.email,
            billingEmail: data.billingEmail,
        }

        const _address: Partial<Address> = {
            zipcode: data.zipcode,
            city: data.city,
            country: data.country,
            houseNumber: data.houseNumber,
            street: data.street,
            floor: data.floor
        }

        await handleSubmitCb(_company, _address)
    }

    // Styles
    const formStyle = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column" as const,
        margin: "0 auto",
    };

    const labelFontStyle = {
        fontWeight: "bold",
    }

    const inputStyle = {
        marginBottom: "20px",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "0.75rem",
    };

    const errorStyle = {
        color: "red",
        fontSize: "0.8rem",
        marginBottom: "10px",
    };

    const createInputCard = (title: string, formName: any, placeholder: string, required: boolean = false, pattern?: RegExp) => {
        return (
            <>
                <label style={labelFontStyle}>{`${t(title)}${required ? "*" : ""}`}</label>
                <input
                    {...register(formName, {
                        required: required ? `${t(title)} ${t("is required")}` : false,
                        pattern: pattern ? { value: pattern, message: `${t(title)} ${t("must be numeric only and length must be less than or equal to 8 characters long")}` } : undefined
                    })}
                    placeholder={`${t(placeholder)}${required ? "*" : ""}`}
                    style={inputStyle}
                />

                {/* Display custom error message based on the type of error */}
                {errors[formName] && <p style={errorStyle}>{errors[formName].message || `${t(title)} ${t("is not valid")}`}</p>}
            </>
        );
    };


    const fileInputRef = React.createRef<HTMLInputElement>();

    const deleteLogo = async () => {
        try {
            await deleteFile(accessToken, refreshToken, `companies/${company.id}/logo.png`);
            setLogo(null);
            setImageLoaded(false);

        } catch (err) {
            console.error("Error in deletion", err);
        }
    };


    const generateSignedUrl = async () => {
        try {
            const response: any = await getPreSignedUrl(accessToken, refreshToken, `companies/${company.id}/logo.png`);
            setLogo(response);
            setImageLoaded(true)

        } catch (err) {
            console.error("Error generating signed URL", err);
        }
    };
    const handleLogoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;

        const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setUploadError(t("File size should not exceed 2 MB."));
                return; // Prevent the upload
            } else {
                setUploadError(''); // Clear any existing error message
            }

            const resizedImage: File = await resizeImage(file, 1400, 400);

            if (!resizedImage) {
                setUploadError(t("Could not resize image. Please try again."));
                return;
            }

            try {
                await uploadFileToS3(accessToken, refreshToken, resizedImage, `companies/${company.id}/logo.png`);

                await generateSignedUrl();
            } catch (err) {
                console.log("Error", err);
            }
        }
    };

    function resizeImage(file, targetWidth, targetHeight): Promise<File> {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    // Calculate the new dimensions
                    let newWidth;
                    let newHeight;
                    const originalAspect = img.width / img.height;
                    const targetAspect = targetWidth / targetHeight;

                    if (originalAspect > targetAspect) {
                        // Image is wider than target (based on aspect ratio)
                        newWidth = targetWidth;
                        newHeight = targetWidth / originalAspect;
                    } else {
                        // Image is taller than target (based on aspect ratio)
                        newWidth = targetHeight * originalAspect;
                        newHeight = targetHeight;
                    }

                    const canvas = document.createElement("canvas");
                    canvas.width = targetWidth;  // Set canvas size to target dimensions
                    canvas.height = targetHeight;
                    const ctx = canvas.getContext("2d");

                    // Fill background to maintain aspect ratio without stretching the image
                    ctx.fillStyle = "rgba(0,0,0,0)"; // Transparent background; change as needed
                    ctx.fillRect(0, 0, targetWidth, targetHeight);

                    // Draw the image centered on the canvas

                    const drawX = (targetWidth - newWidth) / 2;
                    const drawY = (targetHeight - newHeight) / 2;
                    ctx.drawImage(img, drawX, drawY, newWidth, newHeight);

                    canvas.toBlob(function (blob) {
                        // Convert the blob to a File object
                        const resizedFile = new File([blob], file.name, {
                            type: 'image/png', // Or use file.type for the original file's MIME type
                            lastModified: Date.now(),
                        });
                        resolve(resizedFile);

                    }, 'image/png'); // Use 'image/png' to maintain transparency
                };
                img.onerror = function () {
                    reject(new Error("Failed to load image"));
                };
                img.src = e.target.result as any;
            };
            reader.onerror = function () {
                reject(new Error("Failed to read file"));
            };
            reader.readAsDataURL(file);
        });
    }


// Function to handle logo deletion
    const handleLogoDelete = async () => {
        setLogo(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        await deleteLogo()
    };

// Function to render the logo upload input, preview, and delete button
    const renderLogoUpload = () => (
        <>
            <label style={labelFontStyle}>{t("Company Logo")}</label>
            <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                ref={fileInputRef}
                style={inputStyle}
            />
                <>
                    {uploadError && <div style={{ color: 'red', textAlign: 'center' }}>{uploadError}</div>}

            {logo && imageLoaded && (
                <div style={{
                    margin: '10px 0',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative'
                }}>
                    <img
                        src={logo.toString()}
                        alt="Company Logo"
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setImageLoaded(false)}
                        style={{
                            maxWidth: '200px',
                            maxHeight: '200px',
                            display: 'block',
                            margin: 'auto'
                        }}
                    />
                    <i
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            cursor: 'pointer',
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            padding: '5px 8px',
                        }}
                        onClick={handleLogoDelete} className="mdi mdi-delete text-4xl text-primary right-2" ></i>
                </div>
            )}
                </>

        </>
    );



    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center ">
                    <Lottie
                        loop
                        speed={2.5}
                        animationData={lottieJson}
                        play
                        style={{ width: '50%', height: '50%' }}
                    />
                    <p>{t("Retrieving Company")}</p>

            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
            {createInputCard("Name", "name", "Name", true)}
            {createInputCard("Email", "email", "Email", false)}
            {createInputCard("Phone", "phone", "Phone")}
            {createInputCard("Cvr", "cvr", "Cvr", true, /^[0-9]{1,8}$/)}
            {createInputCard("Billing Email", "billingEmail", "Billing Email", false)}

            {type === "edit" && renderLogoUpload()}

            <label style={labelFontStyle} className={"mt-2 text-center"}>{`${t("Address")}*`}</label>
            <div className={"flex flex-col border-1 p-2 border-secondary rounded-xl bg-primaryLighted"}>
                {createInputCard("Street", "street", "Street", true)}
                {createInputCard("Zipcode", "zipcode", "Zipcode", true)}
                {createInputCard("City", "city", "City", true)}
                {createInputCard("Country", "country", "Country", true)}
                {createInputCard("House Number", "houseNumber", "House Number", true)}
                {createInputCard("Floor", "floor", "Floor", false)}
            </div>

            <button type="submit" className="btn btn-primary mt-5 mb-10 bg-secondary text-primary rounded-full p-2"> {type === "create" ? t("Create Company") : t("Save")}</button>
        </form>
    );
};

export default HandleCompanyModal;
