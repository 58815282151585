import React, {useEffect, useState} from 'react';
import "../App.css"
import {useTranslation} from "react-i18next";
import Body from "../components/Body";

function NotifiersScreen() {
    const { t } = useTranslation();
    const [isSiteLoading, setIsSiteLoading] = useState(true);

    useEffect(() => {
        setIsSiteLoading(true);
        setIsSiteLoading(false);
    }, [])

    return (
        <Body
            isSiteLoading={isSiteLoading}
        >
            <div>Notifiers Screen</div>
        </Body>
    )
}


export default NotifiersScreen;
