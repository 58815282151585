import React, {useEffect, useState} from 'react';
import "../App.css"
import {useTranslation} from "react-i18next";
import Header from "../components/Header/Header";
import {useAuth} from "../contexts/useAuth";
import { useNavigate } from 'react-router-dom';
import {findAllByCategories} from "../interactors/ReportInteractor";
import {AxiosError} from "axios";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend, ChartOptions, ChartData,
} from 'chart.js';
import { Pie, Doughnut, Bar, Line } from 'react-chartjs-2';
import {motion, useIsPresent} from "framer-motion";
import Body from "../components/Body";

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

function Dashboard() {
    const { t } = useTranslation();
    const { features, email, accessToken, refreshToken, companies, categories, logout} = useAuth();
    const navigate = useNavigate();
    const [isSiteLoading, setIsSiteLoading] = useState(true);

    useEffect(() => {
        if (features === null) {
            navigate('/');
        }
    }, [features, navigate]);

    useEffect(() => {
        getReports()
    }, [])

    const variants = {
        open: { opacity: 1, x: 0, transition: { duration: 0.3 } },
        closed: { opacity: 0, x: "-100%" },
    }


    // Mock data for the Bar Chart
    const barData: ChartData<'bar'> = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Sales',
                data: [65, 59, 80, 81, 56, 55],
                backgroundColor: '#ddb58f',
                borderColor: '#ddb58f',
                borderWidth: 1,
                borderRadius: 5,
                borderSkipped: true,
                hoverBackgroundColor: '#8d775d',
                hoverBorderColor: '#8d775d',
                hoverBorderWidth: 1,
            },
        ],
    };

// Mock data for the Line Chart
    const lineData: ChartData<'line'> = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
            {
                label: 'Revenue',
                data: [45, 25, 60, 70, 85, 95],
                fill: false,
                borderColor: '#ddb58f',
                tension: 0.1,
            },
        ],
    };

// Mock options for the Bar Chart
    const barOptions: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            x: {
                grid: {
                    color: '#1f3b4f', // Change grid line color for x-axis
                },
                ticks: {
                    color: '#ddb58f', // Change text color for x-axis labels
                },
            },
            y: {
                grid: {
                    color: '#8d775d', // Change grid line color for y-axis
                },
                beginAtZero: true,
                ticks: {
                    color: '#ddb58f', // Change text color for y-axis labels
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#ddb58f', // Change text color of the legend labels
                },
            },
            title: {
                display: true,
                text: 'Monthly Sales Data',
                color: '#ddb58f', // Change title text color
            },
        },
    };


    const lineOptions: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
            x: {
                ticks: {
                    color: '#ddb58f', // Change text color for x-axis labels
                },
                grid: {
                    color: 'rgba(255, 99, 132, 0.2)', // Change grid line color for x-axis
                },
            },
            y: {
                ticks: {
                    color: '#ddb58f', // Change text color for y-axis labels
                },
                grid: {
                    color: 'rgba(54, 162, 235, 0.2)', // Change grid line color for y-axis
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#ddb58f', // Change text color of the legend labels
                },
            },
            title: {
                display: true,
                text: 'Monthly Revenue Data',
                color: '#ddb58f', // Change title text color
            },
        },
    };


// Mock data for the Pie Chart
    const pieData: ChartData<'pie'> = {
        labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [300, 50, 100],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

// Mock data for the Doughnut Chart
    const doughnutData: ChartData<'doughnut'> = {
        labels: ['Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [150, 250, 100],
                backgroundColor: ['#4BC0C0', '#9966FF', '#FF9F40'],
                hoverBackgroundColor: ['#4BC0C0', '#9966FF', '#FF9F40'],
            },
        ],
    };

// Options can be shared or separate for the pie and doughnut charts
    const pieDoughnutOptions: ChartOptions<'pie' | 'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    color: '#ddb58f', // Example color
                },
            },
            title: {
                display: false,
                text: 'Chart Title',
                color: 'black', // Example color
            },
        },
    };


    const getReports = async () => {
        setIsSiteLoading(true);

        let _categories: any[] = [];
        if (!accessToken || !refreshToken) {
            setIsSiteLoading(false);
            return;
        }
        if (categories && categories.length > 0) {
            _categories = categories;
        }

        try {
            const reports = await findAllByCategories(
                accessToken,
                refreshToken,
                _categories ? _categories.map(item => item.id) : [],
                0);
            setIsSiteLoading(false);


        } catch (err: AxiosError | any) {
            switch (err.response?.status) {
                case 403:
                    logout();
                    navigate("/");
                    break;
                default:
                    console.log("ERROR REPORTS with status", err.response.status);
                    break;
            }
        } finally {
            setIsSiteLoading(false);
        }
    }


    return (
        <Body
            isSiteLoading={isSiteLoading}
        >
            <div className={`grid-cols-10 lg:col-span-9 md:col-span-4 h-full `}>
                <div className="h-full grid grid-cols-6 grid-rows-10 md:grid-rows-5 sm:grid-rows-7 gap-10 p-10 text-center">
                    <div className="col-span-6 row-span-1 lg:col-span-3 md:row-span-2 lg:row-span-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                        <Bar data={barData} options={barOptions} />
                    </div>
                    <div className="col-span-6 row-span-1 lg:col-span-3 md:row-span-2 lg:row-span-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                        <Line data={lineData} options={lineOptions} />
                    </div>
                    <div className="col-span-6 row-span-1 row-start-3 lg:row-span-3 md:col-span-2 md:row-span-2 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                        <Pie data={pieData} options={pieDoughnutOptions} />
                    </div>
                    <div className="col-span-6 row-span-1 row-start-4 lg:row-span-3 md:col-span-2 md:row-span-2 md:col-start-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                        <Doughnut data={doughnutData} options={pieDoughnutOptions} />
                    </div>
                    <div className="col-span-6 row-span-1 row-start-5 lg:row-span-3 md:col-span-2 md:row-span-2 md:col-start-5 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                        <Doughnut data={doughnutData} options={pieDoughnutOptions} />
                    </div>
                </div>
            </div>
        </Body>
    )

    /*
    return (
        <div className="justify-center items-center mx-auto gradient_background">
            <div className={"md:hidden"}>
                <Header
                    title={t("Dashboard")}
                    goBackButton={false}
                    goBackButtonRoute={"/"}
                    hideRightButton={true}
                    customLeftButton={(
                            <button
                                className="text-3xl p-2 md:hidden"
                                onClick={toggleMenu}
                            >
                                <i className="mdi mdi-menu text-1xl"></i>
                            </button>
                    )}
                />
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-5 lg:grid-cols-10 w-full`}>
                <motion.div
                    animate={isMenuOpen ? "open" : "closed"}
                    variants={variants}
                    className={`z-20 shadow-xl gradient_background_no_sizing w-[200px] h-[calc(100vh-90px)] absolute left-0 md:w-full md:relative md:h-[calc(100vh)] col-span-1 pt-7`}>
                    <NavigationMenu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
                </motion.div>
                <div className={`md:h-[calc(100vh)] h-[calc(100vh-90px)] grid-cols-10 lg:col-span-9 md:col-span-4 overflow-auto`}>
                    <div className="md:h-[calc(100vh)] h-[calc(100vh-90px)] grid grid-cols-6 grid-rows-10 md:grid-rows-5 sm:grid-rows-7 gap-10 p-10 text-center overflow-y-auto">
                        <div className="col-span-6 row-span-1 lg:col-span-3 md:row-span-2 lg:row-span-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                            <Bar data={barData} options={barOptions} />
                        </div>
                        <div className="col-span-6 row-span-1 lg:col-span-3 md:row-span-2 lg:row-span-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                            <Line data={lineData} options={lineOptions} />
                        </div>
                        <div className="col-span-6 row-span-1 row-start-3 lg:row-span-3 md:col-span-2 md:row-span-2 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                            <Pie data={pieData} options={pieDoughnutOptions} />
                        </div>
                        <div className="col-span-6 row-span-1 row-start-4 lg:row-span-3 md:col-span-2 md:row-span-2 md:col-start-3 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                            <Doughnut data={doughnutData} options={pieDoughnutOptions} />
                        </div>
                        <div className="col-span-6 row-span-1 row-start-5 lg:row-span-3 md:col-span-2 md:row-span-2 md:col-start-5 bg-secondary rounded-2xl p-2 flex items-center justify-center">
                            <Doughnut data={doughnutData} options={pieDoughnutOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
*/
}


export default Dashboard;
