import axios, {AxiosError} from "axios";
import {Account} from "../models/Account";

const baseUrl = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL
const basePattern: string = "account";
export const findCompanyByCompanyId = async (accessToken: string, refreshToken: string, companyId: string) => {
    const url = `${baseUrl}/${basePattern}/GetByCompanyId/${companyId}`;
    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });
        return response.data
    } catch (err) {
        console.log(err)
        throw err as AxiosError;
    }
}

export const updateAccount = async (accessToken: string, refreshToken: string, data: Partial<Account>) => {
    const url = `${baseUrl}/${basePattern}/backend`;
    try {
        const response = await axios.put(
            url,
            data,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data
    } catch (err: AxiosError | any) {
        throw err as AxiosError;

    }
}

/*export const updateAccountByEmail = async (accessToken: string, refreshToken: string, data: Partial<Account>) => {
    const url = `${baseUrl}/${basePattern}/updateByEmail`;
    try {
        const response = await axios.put(
            url,
            data,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data
    } catch (err: AxiosError | any) {
        throw err as AxiosError;

    }
}*/
