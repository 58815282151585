import React, { createContext, useContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import SecureLS from 'secure-ls';

interface AuthContextType {
    features: Feature[] | null;
    setFeatures: Dispatch<SetStateAction<Feature[] | null>>;
    logout: () => void;
    refreshToken: string | null;
    setRefreshToken: Dispatch<SetStateAction<string | null>>;
    accessToken: string | null;
    setAccessToken: Dispatch<SetStateAction<string | null>>;
    companies: Company[] | null;
    setCompanies: Dispatch<SetStateAction<Company[] | null>>;
    email: string | null;
    setEmail: Dispatch<SetStateAction<string | null>>;
    categories: Category[] | null;
    setCategories: Dispatch<SetStateAction<Category[] | null>>;
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
    currentCompany: Company | null;
    setCurrentCompany: Dispatch<SetStateAction<Company | null>>;
    allCompanies: Company[] | null;
    setAllCompanies: Dispatch<SetStateAction<Company[] | null>>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export interface Feature {
    id: string;
    name: string;
    active: boolean;
}

interface Company {
    id: string;
    name: string;
}

interface Category {
    id: string;
    name: string;
}

interface AuthProviderProps {
    children: ReactNode;
}

const ls = new SecureLS({ encodingType: 'aes' });

const getSavedItem = <T, >(key: string): T => {
    const savedItem = ls.get(key);
    return savedItem ? savedItem : null;
}

const saveItem = (key: string, value: any) => {
    ls.set(key, value);
}

const removeItem = (key: string) => {
    ls.remove(key);
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [features, setFeatures] = useState<Feature[] | null>(getSavedItem('features'));
    const [refreshToken, setRefreshToken] = useState<string | null>(getSavedItem('refreshToken'));
    const [accessToken, setAccessToken] = useState<string | null>(getSavedItem('accessToken'));
    const [companies, setCompanies] = useState<Company[] | null>(getSavedItem('companies'));
    const [allCompanies, setAllCompanies] = useState<Company[] | null>(getSavedItem('allCompanies'));
    const [email, setEmail] = useState<string | null>(getSavedItem('email'));
    const [categories, setCategories] = useState<Category[] | null>(getSavedItem('categories'));
    const [currentCompany, setCurrentCompany] = useState<Company | null>(getSavedItem('currentCompany'));
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!getSavedItem('refreshToken') && !!getSavedItem('accessToken') && !!getSavedItem('email'));

    const logout = () => {
        setFeatures(null);
        setAccessToken(null);
        setRefreshToken(null);
        setCompanies(null);
        setAllCompanies(null);
        setEmail(null);
        setCategories(null);
        setIsAuthenticated(false);
        setCurrentCompany(null);
        removeItem('features');
        removeItem('accessToken');
        removeItem('refreshToken');
        removeItem('companies');
        removeItem('email');
        removeItem('categories');
        removeItem('currentCompany');
    };

    useEffect(() => {
        saveItem('features', features);
        saveItem('accessToken', accessToken);
        saveItem('refreshToken', refreshToken);
        saveItem('companies', companies);
        saveItem('allCompanies', allCompanies);
        saveItem('email', email);
        saveItem('categories', categories);
        saveItem("isAuthenticated", isAuthenticated);
    }, [features, accessToken, refreshToken, companies, email, categories, isAuthenticated, allCompanies]);

    useEffect(() => {
        if (currentCompany) {
            saveItem('currentCompany', currentCompany);
        } else {
            saveItem("currentCompany", companies !== null ? companies[0] : null);
        }
    },[currentCompany])

    const value = {
        features,
        setFeatures,
        logout,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        companies,
        setCompanies,
        allCompanies,
        setAllCompanies,
        email,
        setEmail,
        categories,
        setCategories,
        isAuthenticated,
        setIsAuthenticated,
        currentCompany,
        setCurrentCompany
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
