import React, {useEffect, useState} from 'react';
import "../App.css"
import {useTranslation} from "react-i18next";
import Header from "../components/Header/Header";
import {useLocation, useNavigate} from "react-router-dom";

function ImagesScreen() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [images, setImages] = useState<any[]>([]);
    const [notifierId, setNotifierId] = useState(location.state?.notifierId);
    const [description, setDescription] = useState(location.state?.description);
    const [email, setEmail] = useState(location.state?.email);
    const [phone, setPhone] = useState(location.state?.phone);
    const [selectedQuickChoices, setSelectedQuickChoices] = useState<any[]>(location.state?.selectedQuickChoices);

    useEffect(() => {
        if (location.state.images) {
            setImages(location.state.images)
        }
    }, [])

    const handleDeleteImage = (index: number) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        if (updatedImages.length === 0) {
            navigate("/camera", {state: {
                notifierId: notifierId,
                description: description,
                email: email,
                phone: phone,
                selectedQuickChoices: selectedQuickChoices
            }});
        }
        setImages(updatedImages);
    };

    const goBack = () => {
        navigate("/camera", {state: {
            images: images,
            notifierId: notifierId,
            description: description,
            email: email,
            phone: phone,
            selectedQuickChoices: selectedQuickChoices
        }});
    }

    return (
        <div className="justify-center items-center mx-auto gradient_background">
            <Header
                title={t("ImagesScreen")}
                goBackButton={true}
                handleGoBackButton={goBack}
            />
            <div className="flex flex-wrap">
                {images && images.map((image, index) => (
                    <div key={index} className={"flex flex-row pt-6 align-middle justify-center"}>
                        <img src={image} alt="" className={"w-4/5 ml-1"}/>
                        <button onClick={() => handleDeleteImage(index)} className={"w-1/5 mr-3"}>
                            <i className="mdi mdi-delete text-3xl text-error"></i>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default ImagesScreen;
