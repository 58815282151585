import axios, {AxiosError, AxiosResponse} from "axios";
import {BackendResponse} from "../components/Modal/HandleCompanyModal";

const baseUrl = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL
const basePattern: string = "s3";

export async function getPreSignedUrl(accessToken: string, refreshToken: string, key: string): Promise<string> {
    const _url = `${baseUrl}/${basePattern}/getPreSignedUrl?key=${key}`;

    try {
        const response: AxiosResponse = await axios.get(_url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "x-refresh": `Bearer ${refreshToken}`,
            },
        });

        // Assuming the backend returns a JSON object with a URL field
        return response.data.url; // Simply return the URL for client-side use
    } catch (err) {
        throw err; // Rethrow or handle as needed
    }
}

export async function deleteFile(accessToken: string, refreshToken: string, key: string): Promise<void> {
    const _url = `${baseUrl}/${basePattern}/deleteFile?key=${key}`;

    try {
        await axios.delete(_url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "x-refresh": `Bearer ${refreshToken}`,
            },
        });
    } catch (err) {
        throw err;
    }
}

export async function uploadFileToS3(accessToken: string, refreshToken: string, resizedImageAsFile: File, key: string): Promise<string> {
    const _url = `${baseUrl}/${basePattern}/uploadUrl`;

    try {
        const response: AxiosResponse<BackendResponse> = await axios.post(
            _url,
            {
                filename: resizedImageAsFile.name,
                contentType: resizedImageAsFile.type,
                key: key
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            }
        );
        const { url } = response.data as any;

        try {
            await axios.put(url, resizedImageAsFile, {
                headers: {
                    'Content-Type': resizedImageAsFile.type,
                },
            });
            return url
        } catch (err) {
            console.log("err 2", err)
        }
    } catch (err) {
        console.log("err 1", err)
    }
}
