import React from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "../../style/custom-ag-grid.css"
type DataGridComponentProps = {
    colDefs: (ColDef | ColGroupDef)[];
    rowData: any[];
};

const DataGridComponent: React.FC<DataGridComponentProps> = ({ colDefs, rowData }: DataGridComponentProps) => {
    return (
        <div className="ag-grid-transparent no-scrollbar ag-theme-quartz h-[calc(100vh-90px)] md:h-[calc(100vh)] col-span-1 md:col-span-4 lg:col-span-9 w-full">
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                domLayout='autoHeight'
                defaultColDef={{
                    flex: 0.90,
                    suppressMovable: true,
                    resizable: true,
                    autoHeight: true,
                    cellStyle: {
                        alignItems: 'center',
                        borderRight: '1px solid rgba(0,0,0,0.1)',
                    }
                }}
                animateRows={true}
            />
            <div className={"h-[800px]"}></div>
        </div>
    )
}

export default DataGridComponent;
