import axios, {AxiosError} from "axios";
import {Report} from "../models/Report";

const baseUrl = process.env.REACT_APP_NODE_ENV === "production" ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL
const basePattern: string = "report";

export const createReport = async (reportData: Partial<Report>) => {
    const url = `${baseUrl}/${basePattern}`;
    try {
        const response = await axios.post(
            url,
            reportData,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}

export const findAllByCategories = async (accessToken: string, refreshToken: string, categories: Array<String>, page: number) => {
    let categoriesQuery: any = []
    if (categories) {
        categoriesQuery = `categories=${categories}`
    }
    const pageQuery = `page=${page}`

    const url = `${baseUrl}/${basePattern}/findAllByCategories?${categoriesQuery}&${pageQuery}`;

    try {
        const response = await axios.get(
            url,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "x-refresh": `Bearer ${refreshToken}`,
                },
            });

        return response.data
    } catch (err) {
        throw err as AxiosError;
    }
}
