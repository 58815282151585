import React, {useEffect, useState} from 'react';
import "../App.css"
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Body from "../components/Body";
import {useAuth} from "../contexts/useAuth";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {CategoriesCellRenderer, FeaturesCellRenderer} from "../components/DataGrid/CellRendererUtils";
import {updateAccount} from "../interactors/AccountInteractor";
import {useNavigate} from "react-router-dom";
import DataGridComponent from "../components/DataGrid/DataGridComponent";
import 'react-responsive-modal/styles.css';
import CustomModal from "../components/Modal/CustomModal";
import HandleAccountModal from "../components/Modal/HandleAccountModal";
import {v4 as uuid} from "uuid";
import {getInitData, register, sendPasswordSetupEmail} from "../interactors/AuthenticationInteractor";
import {getAllFeatures} from "../interactors/FeatureInteractor";
import {getCategoriesByCompanyId} from "../interactors/CategoryInteractor";
import {capitalizeFirstLetters} from "../utils/StringUtils";
import {toast} from "react-toastify";
import HandleCompanyModal from "../components/Modal/HandleCompanyModal";
import {Company} from "../models/Company";
import {Address} from "../models/Address";
import {createAddress, updateAddressById} from "../interactors/AddressInteractor";
import {AxiosError} from "axios";
import {findCompanyByCompanyId, createCompany, findAllCompanies, updateCompany} from "../interactors/CompanyInteractor";
import {hasSystemFeature} from "../utils/FeaturesUtil";

function CompaniesScreen() {
    const [isSiteLoading, setIsSiteLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {
        accessToken,
        refreshToken,
        currentCompany,
        logout,
        features,
        setFeatures,
        setAccessToken,
        setRefreshToken,
        setEmail,
        setCompanies,
        setCategories,
        setIsAuthenticated,
        setCurrentCompany,
        setAllCompanies
    } = useAuth();

    const [accounts, setAccounts] = useState([]);
    const [currentAccount, setCurrentAccount] = useState<any>({});
    const [companyCategories, setCompanyCategories] = useState([]);
    const [companyFeatures, setCompanyFeatures] = useState([]);
    const navigate = useNavigate();
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const [handleAccountType, setHandleAccountType] = useState<"create" | "edit">("edit");
    const [handleCompanyType, setHandleCompanyType] = useState<"create" | "edit">("edit");
    const {t} = useTranslation();

    const refreshData = async (_currentCompany: any) => {
        setIsSiteLoading(true);
        if (accessToken && refreshToken && _currentCompany) {
            try {
                const rawDataCompany: { accountFeatures: [], accounts: [] } = await findCompanyByCompanyId(accessToken, refreshToken, _currentCompany?.id)
                const rawDataFeatures: any = await getAllFeatures(accessToken, refreshToken)
                const rawDataCategories: any = await getCategoriesByCompanyId(accessToken, refreshToken, _currentCompany?.id)

                setAccounts(rawDataCompany.accounts);
                setCompanyCategories(rawDataCategories);
                setCompanyFeatures(rawDataFeatures);
            } catch (err) {
                //logout();
                //navigate("/")
            } finally {
                setIsSiteLoading(false);
            }
        } else {
            setIsSiteLoading(false);
            //logout();
            //navigate("/")
        }
    }

    const openCompanyModal = (type: | "create" | "edit") => {
        setHandleCompanyType(type);
        setIsCompanyModalOpen(true);
    }

    useEffect(() => {
        refreshData(currentCompany);
    }, [])

    const headerButtons = (
        <div className={"flex flex-row gap-4"}>
            <motion.button
                whileHover={{scale: 1.1}}
                className="flex flex-col items-center cursor-pointer hover:rounded-xl rounded-md"
                onClick={() => openCreateAccountModal()}
            >
                <div
                    className="bg-secondary px-5 py-2 rounded-2xl flex flex-row justify-center items-center gap-2 relative">
                    {windowWidth > 1000 && (
                        <span className="text-primary text-lg text-center font-bold w-full right-2">
                                {t("Create User")}
                        </span>
                    )}
                    <i className="mdi mdi-account-plus text-4xl text-primary right-2"></i>
                </div>
            </motion.button>
            <motion.button
                whileHover={{scale: 1.1}}
                className="flex flex-col items-center cursor-pointer hover:rounded-xl rounded-md"
                onClick={() => openCompanyModal("edit")}>
                <div
                    className="bg-secondary px-5 py-2 rounded-2xl flex flex-row justify-center items-center gap-2 relative">
                    {windowWidth > 1000 && (
                        <span className="text-primary text-lg text-center font-bold w-full right-2">
                                {t("Edit Company")}
                        </span>
                    )}
                    <i className="mdi mdi-domain text-4xl text-primary right-2"></i>
                </div>
            </motion.button>
            
            {features && features.some((feature: any) => feature.name === "system" && feature.active) && (
                <motion.button
                    whileHover={{scale: 1.1}}
                    className="flex flex-col items-center cursor-pointer hover:rounded-xl rounded-md"
                    onClick={() => openCompanyModal("create")}
                >
                    <div
                        className="bg-secondary px-5 py-2 rounded-2xl flex flex-row justify-center items-center gap-2 relative">
                        {windowWidth > 1000 && (
                            <span className="text-primary text-lg text-center font-bold w-full right-2">
                                {t("Create Company")}
                        </span>
                        )}
                        <i className="mdi mdi-domain-plus text-4xl text-primary right-2"></i>
                    </div>
                </motion.button>)}
        </div>
    )

    const [colDefs, setColDefs] = useState<any>([
        {
            headerName: t("Email"),
            field: "email",
            minWidth: 150,
            maxWidth: 200, // Prevents the column from getting too wide
            flex: 1
        },
        {
            headerName: t("First Name"),
            field: "accountData.firstName",
            minWidth: 100,
            flex: 1
        },
        {
            headerName: t("Last Name"),
            field: "accountData.lastName",
            minWidth: 100,
            flex: 1
        },
        {
            headerName: t("Enabled"),
            field: "active",
            cellRenderer: (params: any) => params.value ? t('Yes') : t('No'),
            minWidth: 50,
            maxWidth: 100,
            flex: 0
        },
        {
            headerName: t("Created At"),
            field: "createdAt",
            valueFormatter: (params: any) => {
                const date = new Date(params.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero indexed
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');

                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            },
            minWidth: 150,
            maxWidth: 200,
            flex: 1
        },
        {
            headerName: t("Features"),
            field: "features",
            cellRenderer: FeaturesCellRenderer,
            minWidth: 150,
            flex: 2
        },
        {
            headerName: t("Categories"),
            field: "categories",
            cellRenderer: CategoriesCellRenderer,
            minWidth: 150,
            flex: 2
        },
        {
            headerName: t("Settings"),
            field: "settings",
            cellRenderer: (params: any) => {
                return (
                    <div className={"flex flex-col items-center justify-evenly w-full h-full p-[4px] mt-1"}>
                        <div onClick={() => openEditAccountModal(params)}
                             className={"p-1 align-middle items-center justify-evenly w-[80%] h-full flex bg-secondary rounded-full self-center cursor-pointer mb-2"}>
                            <p className={"text-md text-primary right-2 text-center"}>{t("Edit account")}</p>
                            <i className="mdi mdi-pencil text-2xl text-primary right-2 text-center "/>
                        </div>
                        {!params.data.emailConfirmed && (
                            <div onClick={() => resendPasswordSetupLink(params)}
                                 className={"p-1 align-middle items-center justify-evenly w-[80%] h-full flex bg-secondary rounded-full self-center cursor-pointer mb-2"}>
                                <p className={"text-md text-primary right-2 text-center"}>{t("Reset password")}</p>
                                <i className="mdi mdi-mail text-lg text-primary right-2 text-center "/>
                            </div>)}
                    </div>
                );
            },
            headerStyle: {
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            },
            filter: false,
            sortable: false,
            flex: 0
        },
    ]);

    const openEditAccountModal = (event: any) => {
        setCurrentAccount(event.data);
        setHandleAccountType("edit");
        setIsAccountModalOpen(true);
    }

    const resendPasswordSetupLink = async (event: any) => {
        try {
            await sendPasswordSetupEmail(accessToken, refreshToken, event.data.email)
            toast.success(t("A E-mail has been sent to the created user"));
        } catch (err) {
            toast.error("Failed to send e-mail. If the error persists, please contact support.");
        }
    }

    const openCreateAccountModal = () => {
        setCurrentAccount({});
        setHandleAccountType("create");
        setIsAccountModalOpen(true);
    }

    const onSubmitCompany = async (_company: Partial<Company>, _address: Partial<Address>) => {
        if (handleCompanyType === "create") {

            const addressCreate: Address = {
                createdAt: 0,
                updatedAt: 0,
                street: _address.street,
                city: _address.city,
                zipcode: _address.zipcode,
                country: _address.country,
                houseNumber: _address.houseNumber,
                floor: _address.floor
            }

            const companyToCreate: Partial<Company> = {
                ..._company,
                address: addressCreate
            }
            await createCompany(accessToken, refreshToken, companyToCreate);

            try {
                const response = await getInitData(accessToken, refreshToken);
                if (hasSystemFeature(response.features)) {
                    const allCompaniesFound = await findAllCompanies(accessToken, refreshToken);
                    setAllCompanies(allCompaniesFound);
                    setCurrentCompany(allCompaniesFound.find(company => company.id === currentCompany.id));
                } else {
                    setCurrentCompany(response.companies.find(company => company.id === currentCompany.id));
                }
                setFeatures(response.features);
                setCompanies(response.companies);
                setCategories(response.categories);
            } catch (err) {
                logout();
                return;
            }

            toast.success(t("Company created"));
            setIsCompanyModalOpen(false);

        } else if (handleCompanyType === "edit") {
            //let newCurrentCompany = currentCompany;
            try {
                const addressToUpdate: Address = {
                    createdAt: 0,
                    updatedAt: 0,
                    street: _address.street,
                    city: _address.city,
                    zipcode: _address.zipcode,
                    country: _address.country,
                    houseNumber: _address.houseNumber,
                    floor: _address.floor
                }

                const companyToUpdate: Partial<Company> = {
                    id: currentCompany.id,
                    name: _company.name,
                    billingEmail: _company.billingEmail ? _company.billingEmail : "",
                    email: _company.email,
                    cvr: _company.cvr,
                    phone: _company.phone ? _company.phone : "",
                    address: addressToUpdate
                }
                await updateCompany(accessToken, refreshToken, companyToUpdate);
            } catch (err: AxiosError | any) {
                toast.error(t("Error updating company"));
                return;
            }

            try {
                const response = await getInitData(accessToken, refreshToken);
                if (hasSystemFeature(response.features)) {
                    const allCompaniesFound = await findAllCompanies(accessToken, refreshToken);
                    setAllCompanies(allCompaniesFound);
                    setCurrentCompany(allCompaniesFound.find(company => company.id === currentCompany.id));
                } else {
                    setCurrentCompany(response.companies.find(company => company.id === currentCompany.id));
                }
                setFeatures(response.features);
                setCompanies(response.companies);
                setCategories(response.categories);
            } catch (err) {
                logout();
                return;
            }

            toast.success(t("Company updated"));
            setIsCompanyModalOpen(false);
        }
    }

    const onSubmitAccount = async (data) => {
        if (handleAccountType === "create") {
            const objectToCreate = {
                email: data.email.trim(),
                password: "NEW" + uuid(),
                companies: [currentCompany.id],
                categories: data.categories,
                active: data.active,
                features: data.features,
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
            }
            try {
                const accountCreated = await register(
                    objectToCreate.email,
                    capitalizeFirstLetters(objectToCreate.password),
                    objectToCreate.companies,
                    objectToCreate.firstName,
                    objectToCreate.lastName,
                    objectToCreate.active,
                    objectToCreate.features,
                    objectToCreate.categories
                );
                if (accountCreated) {
                    await sendPasswordSetupEmail(accessToken, refreshToken, objectToCreate.email);
                    setIsAccountModalOpen(false);
                    toast.success(t("Account created successfully"));
                    toast.success(t("A E-mail has been sent to the created user"));
                    await refreshData(currentCompany);
                }
            } catch (err) {
                if (err.response.status === 409) {
                    toast.error(t("Account already exists"));
                } else {
                    toast.error(t("Error creating account"));
                    logout();
                    navigate("/");
                }
            }

        } else if (handleAccountType === "edit") {
            try {
                const accountDataToUpdate = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    categories: data.categories,
                    features: data.features,
                    active: data.active,
                }
                await updateAccount(accessToken, refreshToken, accountDataToUpdate);

            } catch (err) {
                toast.error(t("Error updating account"));
                return;
            }

            /*try {
                const userDataToUpdate = {
                    accountEmail: data.email.trim(),
                    firstName: data.firstName.trim(),
                    lastName: data.lastName.trim(),
                }

                await updateUserByAccountEmail(accessToken, refreshToken, userDataToUpdate);
            } catch (err) {
                toast.error(t("Error updating user"));
                return;
            }*/

            setIsAccountModalOpen(false);
            toast.success(t("Account updated successfully"));
            await refreshData(currentCompany);
        }

    };

    return (
        <Body
            isSiteLoading={isSiteLoading}
            buttons={headerButtons}
            fetchNewData={refreshData}
        >
            <CustomModal
                modalOpen={isAccountModalOpen}
                title={handleAccountType === "create" ? t("Create Account") : t("Edit Account")}
                content={
                    <HandleAccountModal
                        type={handleAccountType}
                        handleSubmitCb={(data) => onSubmitAccount(data)}
                        account={currentAccount}
                        companyCategories={companyCategories}
                        companyFeatures={companyFeatures}
                    />}
                onClose={() => setIsAccountModalOpen(false)}
            />
            <CustomModal
                modalOpen={isCompanyModalOpen}
                title={handleCompanyType === "create" ? t("Create Company") : t("Edit Company")}
                content={
                    <HandleCompanyModal
                        type={handleCompanyType}
                        handleSubmitCb={(company: Partial<Company>, address: Partial<Address>) => onSubmitCompany(company, address)}
                        company={currentCompany}
                    />}
                onClose={() => setIsCompanyModalOpen(false)}
            />
            <DataGridComponent colDefs={colDefs} rowData={accounts}/>
        </Body>
    )
}


export default CompaniesScreen;
