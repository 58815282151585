import React from "react";
import {capitalizeFirstLetters} from "../../utils/StringUtils";

export const CategoriesCellRenderer = (params: any) => {
    if (!params.value || !Array.isArray(params.value) || params.value.length === 0) {
        return <span></span>;
    }

    // Function to get a consistent color for a given string
    const stringToColor = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    };

    // Function to determine text color based on background color brightness
    const getTextColor = (bgColor: string) => {
        const color = bgColor.slice(1);
        const rgb = parseInt(color, 16); // convert hex to a number
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? '#1f3b4f' : 'white';
    };

    return (
        <div className="custom-cell-renderer">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {params.value.map((category: any, index: any) => {
                    const bgColor = stringToColor(category.name);
                    const textColor = getTextColor(bgColor);
                    return (
                        <div
                            key={index}
                            className="m-1 p-2 rounded-full text-center"
                            style={{ backgroundColor: bgColor, color: textColor }}>
                            {capitalizeFirstLetters(category.name)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export const FeaturesCellRenderer = (params: any) => {
    if (!params.value || params.value.length === 0) {
        return <span></span>;
    }

    return (
        <div className="custom-cell-renderer">
            <div style={{ display: 'flex', flexWrap: 'wrap',}}>
                {params.value.map((feature: any, index: any) => (
                    <div
                        style={{
                            backgroundColor:
                                feature.name === "system" ? '#E37F1E' :
                                    feature.name === "company" ? '#8D38E9' :
                                        feature.name === "notifiers" ? '#6a93ee' :
                                            feature.name === "datamodule" ? 'rgba(44,201,64,0.85)' :
                                                feature.name === "reports" ? '#ddb58f' : '#ddb58f',
                        }}
                        key={index}
                        className="m-1 p-2 rounded-full">
                        {capitalizeFirstLetters(feature.name)}
                    </div>
                ))}
            </div>
        </div>
    );
};

