import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import "../App.css";
import {useTranslation} from "react-i18next";
import Header from "../components/Header/Header";
import {useNavigate} from "react-router-dom";
import {login} from "../interactors/AuthenticationInteractor";
import {useAuth} from "../contexts/useAuth";
import {toast} from "react-toastify";
import {hasSystemFeature} from "../utils/FeaturesUtil";
import {findAllCompanies} from "../interactors/CompanyInteractor";

function Login() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const {
        setFeatures,
        setAccessToken,
        setRefreshToken,
        setEmail,
        setCompanies,
        setCategories,
        setIsAuthenticated,
        setCurrentCompany,
        setAllCompanies
    } = useAuth();

    const handleLogin = async (data) => {
        setLoading(true);
        try {
            const loginResponse = await login(data.email, data.password);

            if (hasSystemFeature(loginResponse.features)) {
                const allCompaniesFound = await findAllCompanies(loginResponse.accessToken, loginResponse.refreshToken);
                setAllCompanies(allCompaniesFound);
            }
            setFeatures(loginResponse.features);
            setRefreshToken(loginResponse.refreshToken);
            setAccessToken(loginResponse.accessToken);
            setEmail(loginResponse.email);
            setCompanies(loginResponse.companies);
            setCategories(loginResponse.categories);
            setIsAuthenticated(true);
            setCurrentCompany(loginResponse.companies !== null ? loginResponse.companies[0] : null);
            toast.success(t("Login successful"));
            navigate("/");
        } catch (err) {
            if (err.response.status === 422) {
                toast.error(t("Invalid email or password"));
            }

            if (err.response.status === 404) {
                toast.error(t("Invalid email or password"));
            }

            if (err.response.status === 403) {
                toast.error(t("Invalid Account"));
            }
            setLoading(false);
        }
    };

    return (
        <div className="justify-center items-center mx-auto gradient_background">
            <Header
                title={t("Login")}
                goBackButton={true}
                hideRightButton={true}
                goBackButtonRoute={"/"}
            />
            <div className="flex flex-col my-6 justify-center items-center bg-gradient-to-r">
                <div className="my-2">
                    <img src={require("../assets/logos/reporteerLogo.png")} alt="Logo" className="h-auto w-[250px]"/>
                </div>

                <form onSubmit={handleSubmit(handleLogin)} className="w-full max-w-md px-2">
                    <div className="mb-4">
                        <input
                            type="email"
                            placeholder={t("Email")}
                            {...register("email", {required: true, pattern: /^\S+@\S+\.\S+$/})}
                            className="w-full my-2 p-2 border border-gray-300 rounded-xl shadow-md"
                        />
                        {errors.email && <span className="text-red-500">{t("Invalid email address")}</span>}
                    </div>

                    <div className="mb-6 relative">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            {...register("password", {required: true})}
                            placeholder={t("Password")}
                            className="w-full p-2 border border-gray-300 rounded-xl shadow-md"
                        />
                        <span onClick={() => setPasswordVisible(!passwordVisible)}
                              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                            {passwordVisible ? (
                                <i className={`mdi mdi-eye text-primary text-1xl`}></i>
                            ) : (
                                <i className={`mdi mdi-eye-off text-primary text-1xl`}></i>
                            )}
                        </span>
                    </div>

                    <div className="flex justify-center">
                        <button type="submit"
                                className="bg-secondary text-primary font-bold py-2 px-4 rounded-xl w-[60%]"
                                disabled={loading}>
                            {loading ? t("Loading...") : t("Login")}
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default Login;
