import React from "react";
import Modal from "react-responsive-modal";
import {useTranslation} from "react-i18next";

type CustomModalProps = {
    modalOpen: boolean;
    content: React.ReactNode;
    onClose: () => void;
    title: string;
}

const CustomModal: React.FC<CustomModalProps> = ({ modalOpen, content, onClose, title }: CustomModalProps) => {
    const {t} = useTranslation();
    return (
        <>
            <Modal
                styles={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                    },
                    modal: {
                        scrollbarWidth: "none",
                        backgroundColor: "#ddb58f",
                        borderRadius: "1rem",
                        width: "90%",
                        height: "90%",
                        maxWidth: "600px",
                        maxHeight: "90vh",
                        margin: "auto",
                        padding: "20px",
                    },
                }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                open={modalOpen}
                onClose={onClose}
                center
            >
                <div className={"p-2 h-full"}>
                    <h1 className={"text-2xl font-bold text-secondary"}>{t(title)}</h1>
                    <div className={"p-2 my-4 h-[90%] text-secondary"}>
                        {content}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CustomModal
