import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Select from "react-select";
import {Category} from "../../models/Category";
import {Feature} from "../../models/Feature";
import {Account} from "../../models/Account";
import {capitalizeFirstLetters} from "../../utils/StringUtils";

type HandleAccountModalProps = {
    companyCategories: Partial<Category>[];
    companyFeatures: Partial<Feature>[];
    account: Partial<Account>;
    handleSubmitCb: (data: any) => void;
    type: "edit" | "create"

};

type FormData = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    active: boolean;
    categories: { value: string; label: string }[];
    features: { value: string; label: string }[];
};

const HandleAccountModal: React.FC<HandleAccountModalProps> = ({
                                                                   companyCategories, 
                                                                   companyFeatures,
                                                                   account,
                                                                   type,
                                                                   handleSubmitCb
                                                               }) => {
    
    const {t} = useTranslation();
    const {register, handleSubmit, control, formState: {errors}} = useForm<FormData>({
        defaultValues: {
            categories: account.categories?.map((category: any) => ({
                value: category.id,
                label: capitalizeFirstLetters(category.name)
            })) || [],
            features: account.features?.map((feature: any) => ({
                value: feature.id,
                label: capitalizeFirstLetters(feature.name)
            })) || [],
        }
    });


    const onSubmit: SubmitHandler<FormData> = async (data) => {
        if (type === "edit") {
            data.id = account.id;
            data.email = account.email;
        }
        data.categories = data.categories.map((category: any) => category.value);
        data.features = data.features.map((feature: any) => feature.value);
        await handleSubmitCb(data)
    }

    // Styles
    const formStyle = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column" as const,
        margin: "0 auto",
    };

    const checkboxContainerStyle = {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    };
    const labelStyle = {
        marginRight: "10px",
        fontWeight: "bold",

    };
    const labelFontStyle = {
        fontWeight: "bold",
    }

    const inputStyle = {
        marginBottom: "20px",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "0.75rem",
    };

    const errorStyle = {
        color: "red",
        fontSize: "0.8rem",
        marginBottom: "10px",
    };

    const selectStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '0.75rem',
        }),
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
            <label style={labelFontStyle}>{`${t("Email")}*`}</label>
            <input
                disabled={type === "edit"}
                {...register("email", {
                    required: type === "create",
                    pattern: /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/
                })}
                defaultValue={account.email}
                placeholder={`${t("Email")} *`}
                style={inputStyle}
            />

            {errors.email && <p style={errorStyle}>{t("Email is required and must be a valid email address.")}</p>}
            <label style={labelFontStyle}>{`${t("First Name")}`}</label>
            <input
                {...register("firstName", {required: false, pattern: /^[a-zA-ZæøåÆØÅéÉ\s]*$/})}
                defaultValue={account.accountData?.firstName || ""}
                placeholder={`${t("First Name")}`}
                style={inputStyle}
            />
            {errors.firstName && <p style={errorStyle}>First Name is required and must contain only letters.</p>}

            <label style={labelFontStyle}>{`${t("Last Name")}`}</label>
            <input
                {...register("lastName", {required: false, pattern: /^[a-zA-ZæøåÆØÅéÉ\s]*$/})}
                defaultValue={account.accountData?.lastName || ""}
                placeholder={t("Last Name")}
                style={inputStyle}
            />
            {errors.lastName && <p style={errorStyle}>Last Name is required and must contain only letters.</p>}

            <div className={"mb-5"}>
                <label style={labelFontStyle}>{t("Categories")}</label>
                <Controller
                    name="categories"
                    control={control}
                    render={({field: {onChange, onBlur, value, ref}}) => (
                        <Select
                            placeholder={t("Select categories")}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            onBlur={onBlur}
                            value={value}
                            options={companyCategories.map(category => ({
                                value: category.id,
                                label: capitalizeFirstLetters(category.name)
                            }))}
                            isMulti
                            closeMenuOnSelect={false}
                            styles={selectStyles}
                            ref={ref}
                        />
                    )}
                />
            </div>

            <div className="mb-5">
                <label style={labelFontStyle}>{t("Features")}</label>
                <Controller
                    name="features"
                    control={control}
                    render={({field: {onChange, onBlur, value, name, ref}}) => (
                        <Select
                            placeholder={t("Select features")}
                            isDisabled={account.features && account.features.some((feature: any) => feature.name === "system" && feature.active)}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            onBlur={onBlur}
                            value={value}
                            options={companyFeatures.map((feature) => ({
                                value: feature.id,
                                label: capitalizeFirstLetters(feature.name),
                            }))}
                            isMulti
                            closeMenuOnSelect={false}
                            styles={selectStyles}
                            ref={ref}
                        />
                    )}
                />

            </div>

            <div style={checkboxContainerStyle}>
                <label style={labelStyle}>Enabled</label>
                <input
                    type="checkbox"
                    {...register("active")}
                    defaultChecked={account.active || true}
                />
            </div>

            <button type="submit"
                    className="btn btn-primary mt-5 mb-10 bg-secondary text-primary rounded-full p-2"> {type === "create" ? t("Create Account") : t("Save")}</button>
        </form>
    );
};

export default HandleAccountModal;
