import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"

const resources = {
    en: {
        translation: {
            "Report": "Report",
            "Description": "Description",
            "By submitting this report, you agree to our": "By submitting this report, you agree to our",
            "Terms of Use" : "Terms of Use",
            "Company Information" : "Company Information",
            "Name" : "Name",
            "Email" : "E-mail",
            "Phone" : "Phone",
            "Not found" : "Not found",
            "Home": "Home",
            "ImagesScreen": "Images",
            "Search for": "Search for",
            "Search": "Search",
            "Enter notifier id": "Enter id",
            "QR Code ID": "QR Code ID",
            "NFC tag ID": "NFC tag ID",
            "Location ID": "Location ID",
            "This website uses cookies to enhance the user experience.": "This website uses cookies to enhance the user experience.",
            "Learn more": "Learn more",
            "Accept": "Accept",
            "Decline": "Decline",
            "Accept only necessary": "Accept only necessary",
            "Only necessary": "Only necessary",
            "The id has to be 6 digits": "The id has to be 6 digits",
            "The notifier could not be found. Please try again or contact support if the problem persists": "The notifier could not be found. Please try again or contact support if the problem persists",
            "Phone number": "Phone number",
            "aNewReportCanBeFoundInTheApp": "A new Report can be found in the app",
            "newReport": "New Report",
            "Invalid email format": "Invalid email format",
            "The report was submitted successfully": "The report was submitted successfully",
            "Send": "Send",
            "The notifier is restricted. Please use the app to validate your access": "The notifier is restricted. Please use the app to validate your access",
            "Login": "Login",
            "Password": "Password",
            "'Please enter a valid email address.'": "'Please enter a valid email address.'",
            "firstName": "First name",
            "middleName": "Middle name",
            "lastName": "Last name",
            "categories": "Categories",
            "features": "Features",
            "enabled": "Enabled",
            "createdAt": "Created at",
            "updatedAt": "Updated at",
            "Created At": "Created At",
            "Enabled": "Enabled",
            "Features": "Features",
            "Categories": "Categories",
            "First Name": "First Name",
            "Last Name": "Last Name",
            "Settings": "Settings",
            "Choose company": "Choose company",
            "Yes": "Yes",
            "No": "No",
            "Edit Account": "Edit Account",
            "Save": "Save",
            "Create Account": "Create Account",
            "Create User": "Create User",
            "Invalid email address": "Invalid email address",
            "Invalid Account": "Invalid Account",
            "Invalid email or password": "Invalid email or password",
            "Login successful": "Login successful",
            "Switched to": "Switched to",
            "Account created": "Account created",
            "Confirm Password": "Confirm Password",
            "Password must be between 8 and 100 characters": "Password must be between 8 and 100 characters",
            "Password must include at least one lowercase letter": "Password must include at least one lowercase letter",
            "Password must include at least one uppercase letter": "Password must include at least one uppercase letter",
            "An E-mail has been sent to the created user": "An E-mail has been sent to the created user",
            "Password set successfully": "Password set successfully",
            "Failed to send e-mail. If the error persists, please contact support.": "Failed to send e-mail. If the error persists, please contact support.",
            "Reset": "Reset",
            "Email is required and must be a valid email address.": "Email is required and must be a valid email address.",
            "Error updating account": "Error updating account",
            "Account updated successfully": "Account updated successfully",
            "Select categories": "Select categories",
            "Select features": "Select features",
            "Create Company": "Create Company",
            "Edit Company": "Edit Company",
            "Retrieving Company": "Retrieving Company",
            "is required": "is required",
            "must be numeric only and length must be less than or equal to 8 characters long": "must be numeric only and length must be less than or equal to 8 numbers long",
            "is not valid": "is not valid",
            "Street": "Street",
            "Zipcode": "Zipcode",
            "City": "City",
            "Country": "Country",
            "Floor": "Floor",
            "House Number": "House Number",
            "Billing Email": "Billing Email",
            "Edit account": "Edit account",
            "Reset password": "Reset password",
            "Address": "Address",
            "Company Logo": "Company Logo",
            "File size should not exceed 2 MB.": "File size should not exceed 2 MB.",
        }
    },
    da: {
        translation: {
            "Report": "Indberet",
            "Description": "Beskrivelse",
            "By submitting this report, you agree to our": "Ved at indsende denne indberetning accepterer du vores",
            "Terms of Use" : "Brugsvilkår",
            "Company Information" : "Virksomheds Information",
            "Name" : "Navn",
            "Email" : "E-mail",
            "Phone" : "Telefon",
            "Not found" : "Intet fundet",
            "Home": "Forside",
            "ImagesScreen": "Billeder",
            "Enter notifier id": "Indtast id",
            "Search for": "Søg efter",
            "Search": "Søg",
            "QR Code ID": "QR Kode ID",
            "NFC tag ID": "NFC Tag ID",
            "Location ID": "Lokations ID",
            "This website uses cookies to enhance the user experience.": "Dette websted bruger cookies til at forbedre brugeroplevelsen.",
            "Learn more": "Læs mere",
            "Accept": "Accepter",
            "Decline": "Afvis",
            "Accept only necessary": "Accepter kun nødvendige",
            "Only necessary": "Kun Nødvendige",
            "The id has to be 6 digits": "Id'et skal være 6 cifre",
            "The notifier could not be found. Please try again or contact support if the problem persists": "Notifierne kunne ikke findes. Prøv venligst igen eller kontakt support, hvis problemet ikke løses. ",
            "Phone number": "Telefonnummer",
            "aNewReportCanBeFoundInTheApp": "En ny indberetning kan findes i app'en",
            "newReport": "Ny Indberetning",
            "Invalid email format": "Ugyldig e-mail formatering",
            "The report was submitted successfully": "Din indberetning blev indsendt",
            "Send": "Indsend",
            "The notifier is restricted. Please use the app to validate your access": "Notifierne er beskyttet. Brug app'en for at validere din adgang",
            "Login": "Log ind",
            "Password": "Adgangskode",
            "'Please enter a valid email address.'": "'Vælg venligst en gyldig e-mail adresse.'",
            "firstName": "Fornavn",
            "middleName": "Mellemnavn",
            "lastName": "Efternavn",
            "categories": "Kategorier",
            "features": "Funktioner",
            "enabled": "Aktiveret",
            "createdAt": "Oprettet",
            "updatedAt": "Opdateret",
            "Created At": "Oprettet",
            "Enabled": "Aktiveret",
            "Features": "Funktioner",
            "Categories": "Kategorier",
            "First Name": "Fornavn",
            "Last Name": "Efternavn",
            "Settings": "Indstillinger",
            "Choose company": "Vælg virksomhed",
            "Yes": "Ja",
            "No": "Nej",
            "Edit Account": "Rediger bruger",
            "Save": "Gem",
            "Create Account": "Opret bruger",
            "Create User": "Opret bruger",
            "Invalid email address": "Ugyldig e-mail adresse",
            "Invalid Account": "Ugyldig bruger",
            "Invalid email or password": "Ugyldig e-mail eller adgangskode",
            "Login successful": "Login succesfuldt",
            "Switched to": "Skiftet til",
            "Account created": "Bruger oprettet",
            "Confirm Password": "Bekræft Adgangskode",
            "Invalid password": "Ugyldig adgangskode",
            "Password must be between 8 and 100 characters": "Adgangskoden skal være mellem 8 og 100 tegn",
            "Password must include at least one lowercase letter": "Adgangskoden skal indeholde mindst ét småt bogstav",
            "Password must include at least one uppercase letter": "Adgangskoden skal indeholde mindst ét stort bogstav",
            "An E-mail has been sent to the created user": "En e-mail er blevet sendt til den oprettede bruger",
            "Invalid E-mail": "Ugyldig e-mail",
            "Password set successfully": "Adgangskode succesfuldt indstillet",
            "Failed to send e-mail. If the error persists, please contact support.": "Fejl ved afsendelse af e-mail. Hvis fejlen fortsætter, så kontakt venligst support.",
            "Reset": "Nulstil",
            "Email is required and must be a valid email address.": "E-mail er påkrævet og skal være en gyldig e-mail adresse.",
            "Error updating account": "Fejl ved opdatering af bruger",
            "Account updated successfully": "Bruger opdateret",
            "Select categories": "Vælg kategorier",
            "Select features": "Vælg funktioner",
            "Create Company": "Opret virksomhed",
            "Edit Company": "Rediger virksomhed",
            "Retrieving Company": "Henter virksomhed",
            "is required": "er påkrævet",
            "must be numeric only and length must be less than or equal to 8 characters long": "skal være numerisk og længden skal være mindre end eller lig med 8 tal",
            "is not valid": "er ugyldig",
            "Street": "Vej",
            "Zipcode": "Postnummer",
            "City": "By",
            "Country": "Land",
            "Floor": "Etage",
            "House Number": "Husnummer",
            "Billing Email": "Faktura E-mail",
            "Edit account": "Rediger bruger",
            "Reset password": "Nulstil adgangskode",
            "Address": "Adresse",
            "Company Logo": "Virksomhedslogo",
            "File size should not exceed 2 MB.": "Filstørrelsen skal være mindre end 2 MB.",
        }
    },
};

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        supportedLngs: ['da', 'en'],
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false, // not needed for React as it escapes by default
        },
    });

export default i18n;
