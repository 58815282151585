import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Feature, useAuth} from "../../contexts/useAuth";
import {useTranslation} from "react-i18next";
import {isOnPhone} from "../../utils/PhoneUtils";
import {motion} from "framer-motion"
import {
    hasCompanyFeature,
    hasDataModuleFeature,
    hasNotifiersFeature,
    hasReportsFeature
} from "../../utils/FeaturesUtil";
import {capitalizeFirstLetters} from "../../utils/StringUtils";

function NavigationMenu({isMenuOpen, setIsMenuOpen}: any) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {logout, features, currentCompany, setCurrentCompany, companies} = useAuth();

    const generateMenuItem = (name: string, icon: any, onClick: () => void, color: string, bgColor: string) => {
        return {name: t(name), icon: icon, onClick: onClick, color: color, bgColor: bgColor};
    };

    const getMenuItemsByRoles = (features: Feature[]) => {
        let items = new Set([
            generateMenuItem("Search", <i
                className="mdi mdi-magnify text-2xl text-primary p-5"></i>, () => navigate('/'), "bg-secondary", "bg-primary"),
        ]);
        const addItemIfFeaturePresent = (featureCheck: any, menuItem: any) => {
            if (featureCheck(features)) {
                items.add(menuItem);
            }
        };

        addItemIfFeaturePresent(hasDataModuleFeature, generateMenuItem("Dashboard", <i
            className="mdi mdi-view-dashboard text-2xl text-primary p-5"></i>, () => navigate('/dashboard'), "bg-secondary", "bg-primary"));
        addItemIfFeaturePresent(hasReportsFeature, generateMenuItem("Reports", <i
            className="mdi mdi-file-chart text-2xl text-primary p-5"></i>, () => navigate('/reports'), "bg-secondary", "bg-primary"));
        addItemIfFeaturePresent(hasNotifiersFeature, generateMenuItem("Notifiers", <i
            className="mdi mdi-bell text-2xl text-primary p-5"></i>, () => navigate('/notifiers'), "bg-secondary", "bg-primary"));
        addItemIfFeaturePresent(hasCompanyFeature, generateMenuItem(currentCompany?.name || "Company", <i
            className="mdi mdi-domain text-2xl text-primary p-5"></i>, () => navigate('/companies'), "bg-secondary", "bg-primary"));
        return Array.from(items);
    };

    const menuItems = getMenuItemsByRoles(features || []);

    return (
        <nav
            className={`sticky overflow-scroll md:no-scrollbar md:overflow-auto top-0 z-50 flex flex-col bg-transparent shadow-2xl h-full rounded-r-lg ${isMenuOpen ? 'flex' : 'hidden'} md:flex`}>
            <div className="flex-grow">
                <ul className="list-none p-0 m-0">
                    {menuItems.map((item, index) => (
                        <motion.li
                            whileHover={{scale: 1.2}}
                            key={index}
                            className={`flex flex-col items-center gap-1 px-4 py-3 cursor-pointer hover:rounded-xl rounded-md`}
                            onClick={() => {
                                item.onClick();
                                if (isOnPhone()) {
                                    setIsMenuOpen(false);
                                }
                            }}
                        >
                            <div className={`${item.color} px-4 py-2 rounded-full`}>
                                {item.icon}
                            </div>
                            <span
                                className="text-sm font-medium text-secondary font-semibold">{capitalizeFirstLetters(item.name)}</span>
                        </motion.li>
                    ))}
                </ul>
            </div>
            <div>
                <motion.li
                    whileHover={{scale: 1.2}}
                    key={menuItems.length + 1}
                    className={`flex bottom-20 flex-col items-center gap-1 px-4 py-2 my-10 cursor-pointer hover:rounded-xl rounded-md`}
                    onClick={() => {
                        if (isOnPhone()) {
                            setIsMenuOpen(false);
                        }
                        logout();
                        navigate('/');
                    }}
                >
                    <div className={`bg-error px-4 py-2 rounded-full`}>
                        <i className="mdi mdi-logout text-2xl text-secondary p-5"></i>
                    </div>
                    <span className="text-sm font-medium text-secondary font-semibold">{t("Logout")}</span>
                </motion.li>
            </div>
        </nav>

    );
}

export default NavigationMenu;
