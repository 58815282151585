import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Loading from "./screens/Loading";
import ImagesScreen from "./screens/ImagesScreen";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import DownloadApp from "./components/DownloadApp";
import LoginScreen from "./screens/LoginScreen";
import {AuthProvider, useAuth} from "./contexts/useAuth";
import Dashboard from "./screens/Dashboard";
import NotifiersScreen from "./screens/NotifiersScreen";
import ReportsScreen from "./screens/ReportsScreen";
import RouteTransitionManager from "./utils/RouteTransitionManager";
import CompaniesScreen from "./screens/CompaniesScreen";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PasswordSetup from "./screens/PasswordSetup";

// Lazy loaded components
const Camera = lazy(() => import('./screens/Camera'));
const NotFound = lazy(() => import('./screens/NotFound'));
const Home = lazy(() => import('./screens/Home'));
const ReportForm = lazy(() => import('./screens/ReportForm'));

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!i18n.language) {
            let userLang = navigator.language
            i18n.changeLanguage(userLang.split("-")[0]);
        }

        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1900);

        return () => clearTimeout(timer);
    }, []);



    if (isLoading) {
        return <Loading />;
    }

    return (
        <AuthProvider>
            <BrowserRouter>
                <Suspense fallback={<Loading />}>
                <DownloadApp/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <CookieConsent
                    location="bottom"
                    buttonText={t("Accept")}
                    enableDeclineButton
                    flipButtons={true}
                    declineButtonText={t("Only necessary")}
                    cookieName="CookieConsent"
                    expires={999}
                    overlay
                    declineButtonStyle={{
                        background: "#c35d5e",
                        fontSize: "18px",
                        width: "150px",
                        color: "#2a313a",
                        borderRadius: "14px",
                    }}
                    buttonStyle={{
                        background: "#4ada88",
                        fontSize: "18px",
                        width: "150px",
                        color: "#2a313a",
                        borderRadius: "14px",
                    }}
                    style={{
                        background: "#2a313a",
                        fontSize: "18px",
                        color: "#ddb58f",
                    }}

                >
                    {t("This website uses cookies to enhance the user experience.")}
                    &nbsp;
                    <a href="https://www.privacypolicies.com/live/63f58b83-ad18-4fa0-b837-e75cbd53054b" className={"text-blue-500"} target="_blank" rel="noopener noreferrer">{t("Learn more")}</a>
                </CookieConsent>
                    <RouteTransitionManager>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<LoginScreen />} />
                            <Route path="/passwordSetup/:token" element={<PasswordSetup />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/companies" element={<CompaniesScreen />} />
                            <Route path="/notifier/:notifierId" element={<ReportForm />} />
                            <Route path="/notifiers" element={<NotifiersScreen />} />
                            <Route path="/reports" element={<ReportsScreen />} />
                            <Route path="/api/notifier/:notifierId" element={<ReportForm />} />
                            <Route path="/camera" element={<Camera />} />
                            <Route path="/imagesScreen" element={<ImagesScreen />} />
                            <Route path="/loading" element={<Loading />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </RouteTransitionManager>
            </Suspense>
        </BrowserRouter>
        </AuthProvider>
    );
}

{/*
TODO ADD THIS BACK
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
*/}

root.render(
    <App />
);
