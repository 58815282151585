import React, {useState} from "react";
import {useAuth} from "../contexts/useAuth";
import {capitalizeFirstLetters} from "../utils/StringUtils";
import {useTranslation} from "react-i18next";
import "./HeadlineDropdown.css";
import Select, {StylesConfig} from 'react-select'
import {toast} from "react-toastify";

interface Company {
    id: string;
    name: string;
}

type HeadlineDropdownProps = {
    listOfItems: Company[];
    defaultValue: Company;
    fetchNewData?: (currentCompany: Company) => void;
};

const HeadlineDropdown: React.FC<HeadlineDropdownProps> = ({ listOfItems, defaultValue, fetchNewData }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const {setCurrentCompany, currentCompany, logout} = useAuth()
    const {t} = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleChange = (selectedOption: OptionType | null) => {
        if (selectedOption) {
            const selectedCompany = listOfItems.find(item => item.id === selectedOption.value);
            if (selectedCompany) {
                setCurrentCompany(selectedCompany);
                setSelectedValue(selectedCompany);
                if (fetchNewData) {
                    if (selectedCompany.id !== defaultValue.id) {
                        toast.info(t(`${t("Switched to")}: ${capitalizeFirstLetters(selectedCompany.name)}`), {
                            autoClose: 500,
                            hideProgressBar: true,
                        });
                        fetchNewData(selectedCompany);
                    }
                }
            }
        } else {
            logout();
        }
    };

    interface OptionType {
        value: string;
        label: string;
    }

    const options = listOfItems.map(company => ({
        value: company.id,
        label: capitalizeFirstLetters(company.name)
    }));


    const colors = {
        primary: '#ddb58f',
        secondary: '#1f3b4f',
        primaryGreyed: '#8d775d',
        primaryLightened: '#efd5bd',
        secondaryGreyed: '#2a313a',
    }

    const customStyles: StylesConfig<OptionType, false> = {
        control: (base, state) => ({
            ...base,
            fontSize: '1.125rem',
            fontWeight: "bold",
            color: colors.primary,
            padding: "10px",
            backgroundColor: colors.secondary,
            borderRadius: menuIsOpen ? '1rem 1rem 0 0' : '1rem',
            transition: 'border-radius 0s ease, border-color 0s ease', // Speed up the animation
            borderWidth: '0',
            width: windowWidth < 768 ? '100%' : '300px',
            borderColor: state.isFocused ? colors.primary : 'transparent',
            boxShadow: state.isFocused ? '0 0 0 1px desiredFocusedColor' : 'none',
        }),
        option: (base, { isFocused, isSelected, data }) => {
            const isFirst = data === options[0];
            const isLast = data === options[options.length - 1];

            return {
                ...base,
                fontWeight: "bold",
                padding: "20px",
                backgroundColor:
                    isSelected ?
                        colors.primaryLightened :
                        isFocused ?
                            colors.primaryLightened :
                            colors.primaryLightened,
                color: isSelected ? colors.secondary : isFocused ? colors.secondary : colors.secondary,
                '&:hover': {
                    backgroundColor: isFocused || isSelected ? colors.secondary : colors.primaryLightened,
                    color: isFocused || isSelected ? colors.primary : colors.secondary
                },
                borderRadius: isLast ? '0 0 10px 10px' :
                        '0',
            };
        },
        menu: base => ({
            ...base,
            borderRadius: '0 0 10px 10px',
            backgroundColor: colors.primaryLightened,
            marginTop: 0,
        }),
        menuList: base => ({
            ...base,
            padding: 0
        }),
        singleValue: (base) => ({
            ...base,
            color: colors.primary,
        }),
        placeholder: (base) => ({
            ...base,
            color: colors.primary,
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: colors.primary,
            transition: 'color 0.2s',
            ':hover': {
                color: state.isFocused ? colors.primaryLightened : colors.primary // Keep the color the same on hover
            }
        }),
        clearIndicator: base => ({
            ...base,
            color: colors.primary
        })
    };
    return (
        <Select<OptionType, false>
            value={{ value: currentCompany.id, label: capitalizeFirstLetters(currentCompany.name)}}
            onChange={handleChange}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            isSearchable={false}
            options={options}
            styles={customStyles}
        />
    );
}

export default HeadlineDropdown;
