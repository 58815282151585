import React, {useState} from 'react';
import {useAuth} from "../../contexts/useAuth";
import {useTranslation} from "react-i18next";
import HeadlineDropdown from "../HeadlineDropdown";
import {capitalizeFirstLetters} from "../../utils/StringUtils";
import {hasSystemFeature} from "../../utils/FeaturesUtil";

type Company = {
    id: string;
    name: string;
};

type ScreenHeaderProps = {
    fetchNewData?: (currentCompany: Company) => void;
    buttons?: React.ReactNode
};


const ScreenHeader: React.FC<ScreenHeaderProps> = ({buttons, fetchNewData}: ScreenHeaderProps) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {companies, currentCompany, setCurrentCompany, features, allCompanies} = useAuth()
    const { t } = useTranslation();

    const companiesToUse = hasSystemFeature(features) ? allCompanies : companies
    
    return (
        <div className="mb-6">
            <div className="flex flex-row justify-between">
                {companiesToUse.length > 1 ? (<HeadlineDropdown
                    fetchNewData={fetchNewData}
                    listOfItems={companiesToUse ? companiesToUse : []}
                    defaultValue={currentCompany ? currentCompany : { id: "", name: ""}}/>) : windowWidth > 768 ? (
                        <div className="flex flex-row">
                            <h1 className="text-6xl text-secondary font-bold">{currentCompany ? capitalizeFirstLetters(currentCompany.name) : "Company"}</h1>
                        </div>
                    ) : null}
                {buttons ? (
                    <div className="flex flex-row">
                        {buttons}
                    </div>
                ) : (
                    <div className={"flex flex-row"}></div>
                )}
            </div>
        </div>
    );
};
export default ScreenHeader;
