import React, {useState} from "react";
import ScreenHeader from "./Navigation/ScreenHeader";
import Header from "./Header/Header";
import {motion} from "framer-motion";
import NavigationMenu from "./Navigation/NavigationMenu";
import Lottie from "react-lottie-player";
import lottieJson from "../assets/animations/loadingLogo.json";
import {useTranslation} from "react-i18next";
import {useAuth} from "../contexts/useAuth";
import {capitalizeFirstLetters} from "../utils/StringUtils";
import {ToastContainer} from "react-toastify";

type BodyProps = {
    isSiteLoading: boolean
    children: React.ReactNode
    buttons?: React.ReactNode
    fetchNewData?: (currentCompany: any) => void
};

const Body: React.FC<BodyProps> = ({isSiteLoading, children, buttons, fetchNewData}: BodyProps) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth > 768);
    const {currentCompany} = useAuth();

    const variants = {
        open: { opacity: 1, x: 0, transition: { duration: 0.3 } },
        closed: { opacity: 0, x: "-100%" },
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="justify-center items-center mx-auto gradient_background ">
            <div className={"md:hidden z-50"}>
                <Header
                    title={`${currentCompany ? capitalizeFirstLetters(currentCompany.name) : "Company"}`}
                    goBackButton={false}
                    goBackButtonRoute={"/"}
                    hideRightButton={true}
                    customLeftButton={(
                        <button
                            className="text-3xl p-2 md:hidden"
                            onClick={toggleMenu}
                        >
                            <i className="mdi mdi-menu text-1xl"></i>
                        </button>
                    )}
                />
            </div>
            <div className={`grid grid-cols-1 md:grid-cols-5 lg:grid-cols-10 w-full`}>
                <motion.div
                    animate={isMenuOpen ? "open" : "closed"}
                    variants={variants}
                    className={`z-20 shadow-xl gradient_background_no_sizing w-[200px] h-[calc(100vh-90px)] absolute left-0 md:w-full md:relative md:h-[calc(100vh)] col-span-1 pt-7`}>
                    <NavigationMenu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
                </motion.div>
                {isSiteLoading ? (
                    <div className="overflow-scroll no-scrollbar gradient_background_no_sizing h-[calc(100vh-90px)] md:h-[calc(100vh)] col-span-1 md:col-span-4 lg:col-span-9 w-full p-10">
                        <ScreenHeader
                            fetchNewData={fetchNewData}
                            buttons={buttons}/>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Lottie
                                loop
                                speed={2.5}
                                animationData={lottieJson}
                                play
                            />
                        </div>
                    </div>
                ) : (
                    <div className="overflow-scroll no-scrollbar gradient_background_no_sizing h-[calc(100vh-90px)] md:h-[calc(100vh)] col-span-1 md:col-span-4 lg:col-span-9 w-full p-10">
                        <ScreenHeader
                            fetchNewData={fetchNewData}
                            buttons={buttons}/>
                        {children}
                    </div>

                )}
            </div>
        </div>
    )
}

export default Body;
