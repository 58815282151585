import React, {useState} from 'react';

const DownloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const [showBanner, setShowBanner] = useState(true);


    const getStoreLink = () => {
        if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/apps/details?id=com.matiasgramkow.reporteer";
        }
        return null;
    };

    const storeLink = getStoreLink();

    if (!storeLink) return null;

    if (showBanner) {
        return (
            <div className={"bg-secondary flex flex-row justify-between items-center w-full p-2 py-4"}>
                <div className={"flex flex-row items-center w-1/3"}>
                    <i className={`mdi mdi-close text-primaryGreyed text-l mx-2`} onClick={() => setShowBanner(false)}></i>
                    <div className={"bg-primary p-2 rounded-md shadow"}>
                        <img src={require("../assets/logos/reporteerLogoOnly.png")} width={40} height={40} alt="App Logo" className={"app-logo"}/>
                    </div>
                </div>
                <div className={"w-1/3 text-center"}>
                    <p className={"text-primary text-lg"}>Reporteer</p>
                    <p className={"text-primaryGreyed text-sm"}>Productivity</p>
                </div>
                <div className={"w-1/3 text-right pr-4"}>
                    <a href={storeLink} target="_blank" rel="noopener noreferrer" className={"bg-primary p-2 rounded-md shadow text-secondary"}>Download</a>
                </div>
            </div>

        );
    } else {
        return null;
    }
};

export default DownloadApp;
