import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Header from "../components/Header/Header";
import { useTranslation } from "react-i18next";
import {setPasswordByLink} from "../interactors/AuthenticationInteractor";
import {toast} from "react-toastify";

interface FormValues {
    password: string;
    confirmPassword: string;
}

const PasswordSetup: React.FC = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
    const { token } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (data: FormValues) => {
        const { password } = data;
        try {
            await setPasswordByLink(password, token);
            toast.success("Password set successfully");
            navigate("/");
        } catch (err) {
            toast.error("Failed to set password. If the error persists, please contact support.");
        }
    };

    const passwordValue = watch("password");

    const validatePassword = (password: string) => {
        const errors = [];
        if (password.length < 8 || password.length > 100) {
            errors.push(t('Password must be between 8 and 100 characters'));
        }
        if (!/[a-z]/.test(password)) {
            errors.push(t('Password must include at least one lowercase letter'));
        }
        if (!/[A-Z]/.test(password)) {
            errors.push(t('Password must include at least one uppercase letter'));
        }

        if (errors.length === 0) {
            return true;
        } else {
            return errors.join(', ');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="justify-center items-center mx-auto gradient_background">
            <Header
                title={t("Set Password")}
                hideLeftButton={true}
            />
            <div className="flex flex-col my-6 justify-center items-center bg-gradient-to-r">
                <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md px-2">
                    {/* Password field */}
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            {t("Password")}
                        </label>
                        <div className="flex items-center">
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                {...register('password', { validate: validatePassword })}
                                className="w-full my-2 p-2 border border-gray-300 rounded-xl shadow-md"
                            />
                            <button type="button" onClick={togglePasswordVisibility} className="ml-2">
                                {showPassword ?
                                    <i className={`mdi mdi-eye-off text-primary text-1xl`}></i> :
                                    <i className={`mdi mdi-eye text-primary text-1xl`}></i>
                                }
                            </button>
                        </div>
                        {errors.password && (
                            errors.password.message.split(', ').map((error, index) => (
                                <p key={index} className="text-red-500">{error}</p>
                            ))
                        )}
                    </div>

                    {/* Confirm Password field */}
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                            {t("Confirm Password")}
                        </label>
                        <div className="flex items-center">
                            <input
                                id="confirmPassword"
                                type={showPassword ? "text" : "password"}
                                {...register('confirmPassword', {
                                    validate: value => value === passwordValue || t('Passwords must match')
                                })}
                                className="w-full my-2 p-2 border border-gray-300 rounded-xl shadow-md"
                            />
                            <button type="button" onClick={togglePasswordVisibility} className="ml-2">
                                {showPassword ?
                                    <i className={`mdi mdi-eye-off text-primary text-1xl`}></i> :
                                    <i className={`mdi mdi-eye text-primary text-1xl`}></i>
                                }
                            </button>
                        </div>
                        {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword.message}</p>}
                    </div>

                    {/* Submit button */}
                    <div className="flex justify-center">
                        <button type="submit" className="bg-secondary text-primary font-bold py-2 px-4 rounded-xl w-[60%]" disabled={loading}>
                            {loading ? t("Loading...") : t("Set password")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PasswordSetup;
