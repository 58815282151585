// Header.tsx
import React from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../contexts/useAuth";
import {isOnPhone} from "../../utils/PhoneUtils";

type HeaderProps = {
    title: string;
    hideLeftButton?: boolean;
    hideRightButton?: boolean;
    goBackButton?: boolean;
    goBackButtonRoute?: string;
    handleGoBackButton?: () => void;
    showDashboardButtonLeft? : boolean
    customLeftButton? : React.ReactNode
};

const Header: React.FC<HeaderProps> = ({ title, goBackButton= false, hideLeftButton= false, goBackButtonRoute, handleGoBackButton, hideRightButton, showDashboardButtonLeft, customLeftButton}: HeaderProps) => {
    const navigate = useNavigate();
    const {features, logout} = useAuth();

    const goBack = () => {
        if (goBackButtonRoute) {
            navigate(goBackButtonRoute);
        } else {
            navigate(-1);
        }
    }

    return (
        <div className="h-[90px] bg-primary shadow-md justify-center items-center mx-auto sticky top-0 z-10">
            <div className="grid grid-cols-3 items-center p-4 mx-auto w-full" style={{maxWidth: "800px"}}>
                {hideLeftButton && (
                    <div className="justify-self-start" >
                    </div>
                )}
                {!hideLeftButton && goBackButton && (
                    <div className="justify-self-start" >
                            <button
                                onClick={() => handleGoBackButton ? handleGoBackButton() : goBack()}
                                className={`rounded-full 'bg-secondary'`}
                                title="Dansk"
                            >
                                <i className="mdi mdi-arrow-left text-3xl"></i>
                            </button>
                    </div>
                )}
                {title && !hideLeftButton && !goBackButton && customLeftButton && (
                    <div>
                        {customLeftButton}
                    </div>
                )}
                {!hideLeftButton && features && showDashboardButtonLeft && (
                    <div className="justify-self-start" >
                        <button
                            onClick={() => navigate("/dashboard")}
                            className="bg-secondary text-primary rounded-full p-2 mr-2 px-4 justify-center items-center text-center"
                            title="Dansk"
                        >
                            <div className={"flex flex-row"}>
                                <p>
                                    Dashboard
                                </p>
                                <p>
                                    <i className="mdi mdi-view-dashboard text-1xl"></i>
                                </p>
                            </div>


                        </button>
                    </div>
                )}
                {!hideLeftButton && !features && showDashboardButtonLeft && (
                    <div className="justify-self-start" >
                    </div>
                )}
                {title && !hideLeftButton && !goBackButton && !customLeftButton && (
                    <div className="justify-self-start mx-auto mb-2 " >
                        <a href="https://www.reporteer.dk/">
                            <img src={require("../../assets/logos/reporteerLogo.png")} alt="Logo" className="h-auto w-full 2xl:h-11 xl:h-11 lg:h-11 md:h-11 sm:h-11 w-auto" />
                        </a>
                    </div>
                )}
                {title &&(<h1 className="text-2xl sm:text-2xl text-center text-secondary ">{title}</h1>)}
                {!title && !isOnPhone() && (
                    <div className="justify-self-start mx-auto mb-2" >
                        <a href="https://www.reporteer.dk/">
                            <img src={require("../../assets/logos/reporteerLogo.png")} alt="Logo" className="h-auto w-full 2xl:h-11 xl:h-11 lg:h-11 md:h-11 sm:h-11 w-auto" />
                        </a>
                    </div>
                )}
                {!title && isOnPhone() && (
                    <div className="justify-self-start mx-auto mb-2" >
                    </div>
                )}
                {!hideRightButton && features === null && (
                    <div className="justify-self-end">
                        <button
                            type="submit"
                            className="bg-secondary text-primary rounded-full p-2 mr-2 px-4 justify-center items-center text-center"
                            onClick={() => navigate("/login")}
                        >
                            Login
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
