import {Feature} from "../contexts/useAuth";

export const hasSystemFeature = (features: Feature[]): boolean => {
    return features && features.some(feature => feature.name === Features.SYSTEM && feature.active);
};
export const hasReportsFeature = (features: Feature[]): boolean => {
    return features && features.some(feature => feature.name === Features.REPORTS && feature.active);
};

export const hasNotifiersFeature = (features: Feature[]): boolean => {
    return features && features.some(feature => feature.name === Features.NOTIFIERS && feature.active);
};

export const hasCompanyFeature = (features: Feature[]): boolean => {
    return features && features.some(feature => feature.name === Features.COMPANY && feature.active);
};

export const hasDataModuleFeature = (features: Feature[]): boolean => {
    return features && features.some(feature => feature.name === Features.DATAMODULE && feature.active);
}
export enum Features {
    REPORTS = "reports",
    SYSTEM = "system",
    NOTIFIERS = "notifiers",
    COMPANY = "company",
    DATAMODULE = "datamodule",

}


